import React from "react";
import styled from "styled-components";
interface Props {
  className?: any;
}
const StyledIconBoxes = styled.svg`
  & .path {
    opacity: 0.4;
    transition: opacity 0.3s ease;
  }
`;
const FileExport = ({ className }: Props): JSX.Element => {
  return (
    <StyledIconBoxes
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6 6H7.5V9H6V6ZM6 10.5H7.5V13.5H6V10.5ZM10.5 6H12V9H10.5V6ZM10.5 10.5H12V13.5H10.5V10.5ZM6 15H7.5V18H6V15ZM10.5 15H12V18H10.5V15Z"
        fill="#BCBAB7"
      />
      <path
        d="M22.5 10.5C22.5 10.1022 22.342 9.72064 22.0607 9.43934C21.7794 9.15804 21.3978 9 21 9H16.5V3C16.5 2.60218 16.342 2.22064 16.0607 1.93934C15.7794 1.65804 15.3978 1.5 15 1.5H3C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V22.5H22.5V10.5ZM3 3H15V21H3V3ZM16.5 21V10.5H21V21H16.5Z"
        fill="#BCBAB7"
      />
    </StyledIconBoxes>
  );
};

export { FileExport };
