import React from "react";

type EventIconProps = {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
};

const EventIcon: React.FC<EventIconProps> = ({
  width = 24,
  height = 24,
  color = "#AEA9A1",
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7 2C7.55228 2 8 2.44772 8 3V4H16V3C16 2.44772 16.4477 2 17 2C17.5523 2 18 2.44772 18 3V4H19C20.1046 4 21 4.89543 21 6V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V6C3 4.89543 3.89543 4 5 4H6V3C6 2.44772 6.44772 2 7 2ZM5 8V20H19V8H5ZM7 11H9V13H7V11ZM11 11H13V13H11V11ZM15 11H17V13H15V11Z"
        fill={color}
      />
    </svg>
  );
};

export default EventIcon;
