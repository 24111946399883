import styled from "styled-components";

export const StyledAside = styled.aside`
  min-height: 100vh;
  height: 100%;
  text-transform: capitalize;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  background: white;

  &.collapsed {
    width: 60px;
  }

  &.expanded {
    width: 240px;
  }

  .navigation {
    padding: 70px 0px 0px 0;
    nav {
      .main-menu {
        padding: 10px 0;
        width: 63px;
        border-right: 1px solid rgb(204, 204, 204);
        height: calc(-72px + 100vh);
        z-index: 1000;
        background: rgb(249, 249, 249);
        padding-top: 20px;

        li {
        padding:0 10px;
         padding-bottom: 15px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
a{
  padding: 8px 0;
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
          background: rgb(243, 243, 243);


     &.active,
          &:hover {
            border-radius: 5px;
            background-color: #363a30;
            svg {
              fill: rgb(254, 186, 48);
              opacity: 1;
            }
            path {
              fill: rgb(254, 186, 48);
              opacity: 1;
            }
            
          }
            &.active.disabledActive{
                background: rgb(243, 243, 243);
                border-radius: 0;
                svg{
                    fill: #3a3630;
                    path{
                        fill: #3a3630;
                        opacity: 0.4;
                        }
                }
            }
}
         
              &:hover {
               .submenu{
             display:flex;}
              }

          .submenu {
            position: absolute;
            top: 0;
           left: 60px;

            background: rgb(249, 249, 249);
            width: 200px;
            border-right: 1px solid rgb(204, 204, 204);
            height: 100vh;
            padding-top: 0;
            display: none;
            cursor: default;
    .subMenuCover{
        background: #fff;
    padding-top: 20px;
    border-left: 1px solid rgb(204, 204, 204);
    width: 100%;
    }
            h2 {
              text-transform: uppercase;
              font-family: lato, sans-serif;
              font-size: 18px;
              color: #000;
              padding-left: 20px;
            }
            ul {
              opacity: 1;
              height: 0;
              transition: all ease 0.5s;

              position: relative;
              top: 0;
              right: 0;
              height: auto;
              padding: 10px;
              margin: 10px;
              color: #363a30;
              li {
                margin-bottom: 10px;
                display: block;
                background: none;
                padding: 0;

                a {
                  font-size: 15px;
                  font-weight: 400;
                  padding-left: 5px;
                  display: block;
                  position: relative;
                  color: #a9a9a9;
                  white-space: nowrap;
                  padding: 10px;
                  background: rgb(251, 251, 251);

                  &:hover {
                    background-color: rgb(242, 242, 242);
                    border-radius: 7px;
                  }

                  &.active {
                    color: #363a30;
                    background-color: rgb(254, 186, 48);
                    padding: 10px;
                    border-radius: 7px;
                  }
                }
                &.collapsed {
                  display: none;
                }
              }
            }
            .toggle-icon {
              position: absolute;
              top: 7px;
              right: -10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 1;
    border: 1px solid rgb(204, 204, 204);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    svg,svg path{
      fill: #000;
    }
          }

          .arrowIcon {
            position: absolute;
            right: 22px;
            top: 24px;
            transform: translateY(-50%) rotate(180deg);
          }

          &.active {
            .submenu ul {
              opacity: 1;
              height: auto;
            }
          }
        }
       
       
      }
    }
  }
`;